import * as React from 'react'
import { Provider } from 'react-redux'
import { store } from 'src/store/index'

export const withReduxContext =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  (props: P) =>
    (
      <WithReduxContext>
        <Component {...props} />
      </WithReduxContext>
    )

export default function WithReduxContext({
  children,
}: {
  children: React.ReactNode
}) {
  return <Provider store={store}>{children}</Provider>
}
